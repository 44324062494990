import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { FaGithub } from 'react-icons/fa';

const ProjectCard = ({ title, description, link, image, tools, github, CardCarousel = false }) => {

    return (
        <Card className="project-card" 
        style={{ minHeight: '25rem' }}>
            {image && <Card.Img variant="top" src={image} style={{ maxHeight: '10rem', objectFit: 'cover' }} 
            alt={"image décorative projet"+{title}}
            />}
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>{description}</Card.Text>
                {tools && (
                    <div className="mt-3">
                        {tools.map((tool, index) => (
                            <span key={index}
                                style={{ display: 'inline-block', margin: '0.2rem', padding: '0.2rem 0.4rem', borderRadius: '5px', backgroundColor: 'var(--bs-secondary)' , color: 'var(--bs-light)' }}
                            >{
                                    tool}
                            </span>
                        ))}
                    </div>
                )

                }

                {link && (
                    <Button variant="primary" href={link} target="_blank" rel="noopener noreferrer" style={{ marginTop: '10px' }} >
                        voir le projet
                    </Button>
                )}
                {github && (
                    <div>
                        {github && (

                            github.map((github, index) => (

                                <span key={index}>

                                    <Button  variant="dark"
                                     href={github} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' , marginTop: '10px' }}>
                                        < FaGithub style={{ marginRight: '10px'}}/>
                                        github
                                    </Button>
                                </span>

                            ))
                        )}
                    </div>
                )}

            </Card.Body>
        </Card>
    );
};

export default ProjectCard;