import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import projectList from '../asset/data/projectList';
import { Card, Button } from 'react-bootstrap';
import { FaGithub } from 'react-icons/fa';


const ProjectsPage = () => {
  //read aftre the / in the url
  const url = window.location.pathname.split('/').pop();
  //if the url is empty, we are on the projects page
  if (url === 'projects') {
    const columns = [[], [], []];
    projectList.forEach((project, index) => {
      columns[index % 3].push(project);
    });

    return (
      <Container
      style={{
        padding: "20px",
        backgroundColor: "var(--bs-dark)",
        color: "white",
        margin: "0px",
        maxWidth: "100%",
        minHeight: "100vh",
      }}
      >
      <h1>Projets</h1>
      <p>Voici quelques projets sur lesquels j'ai travaillé.</p>
      <Row>
        {columns.map((column, colIndex) => (
        <Col md={4} xl={4} xs={12} key={colIndex}>
          {column.map((project, index) => {
          const { image, title, description, tools, link, github } = project;
          return (
            <Card className="project-card" key={index} style={{ marginBottom: '20px' }}>
            {image && <Card.Img variant="top" src={image} style={{ objectFit: 'cover' , maxHeight: '25rem' , backgroundColor: 'var(--bs-black)'

            }} 
              alt={"image décorative projet"+{title}}
            />}
            <Card.Body>
              <Card.Title>{title}</Card.Title>
              <Card.Text>{description}</Card.Text>
              {tools && (
              <div className="mt-3">
                {tools.map((tool, index) => (
                <span
                  key={index}
                  style={{
                  display: 'inline-block',
                  margin: '0.2rem',
                  padding: '0.2rem 0.4rem',
                  borderRadius: '5px',
                  backgroundColor: 'var(--bs-secondary)',
                  color: 'var(--bs-light)',
                  }}
                >
                  {tool}
                </span>
                ))}
              </div>
              )}
              {link && (
              <Button
                variant="primary"
                href={link}
                
                rel="noopener noreferrer"
                style={{ marginTop: '10px' }}
              >
                voir le projet
              </Button>
              )}
              {github && (
              <div>
                {github.map((github, index) => (
                <span key={index}>
                  <Button
                  variant="dark"
                  href={github}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginRight: '10px', marginTop: '10px' }}
                  >
                  <FaGithub style={{ marginRight: '10px' }} />
                  github
                  </Button>
                </span>
                ))}
              </div>
              )}
            </Card.Body>
            </Card>
          );
          })}
        </Col>
        ))}
      </Row>
      </Container>
    );
  } else {
    return null;
  }


};

export default ProjectsPage;