import * as React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, CardHeader } from 'react-bootstrap';

const Diplome = () => {

    return (
        <Container fluid style={{ paddingTop: '20px', paddingBottom: '20px',  }}>
            <Row>
                <h1 style={{ padding: '10px', textAlign: 'center', color: 'var(--bs-light)' }}>
                    Parcours universitaire
                </h1>
            </Row>
            <Row style={{ padding: '10px', width: '100%', margin: '0' }}>
                <Col style={{ margin: 'auto',paddingBottom :'10px' }}  md={6} lg={6} xl={6} sm={12} >
                    <Card style={{ borderRadius: '10px' }}>
                        <CardHeader style={{ border: '0px' }}>

                            <CardTitle>
                                <h2 style={{ paddingTop: '10px', textAlign: 'center' }}>
                                    Gestion des Risques (MRI)
                                    <p className='text-muted' >
                                        Diplôme d'ingénieur
                                    </p>
                                </h2>
                            </CardTitle>
                            <CardSubtitle>
                                <h3 style={{ textAlign: 'center', fontSize: '1.2rem' }} className='text-muted' >
                                    INSA Centre Val de Loire
                                </h3>
                            </CardSubtitle>
                        </CardHeader>
                        <CardBody style={{ padding: '10px' }}>

                            <p style={{ }}>
                                Le but du département Maîtrise des Risques Industriels est de former des ingénieurs capables de maîtriser les risques industriels et de contribuer à la sécurité des personnes, des biens et de l'environnement. Y compris la sécurité des systèmes d'information.
                            </p>
                            <h4 style={{ padding: '7px'}}>
                                Option de 4ème année : Systèmes Avancés
                            </h4>
                            <p style={{  }}>
                                Cette option permet d'acquérir des compétences en matière d'ingénierie systèmes. L'ingénierie système est une approche globale de la conception, de la réalisation et de la maintenance des systèmes complexes. Elle vise à garantir la cohérence et la compatibilité des éléments d'un système, à optimiser les performances et à minimiser les coûts. Cette approche a créé le génie logiciel par exemple.
                            </p>
                            <h4 style={{ padding: '7px' }}>
                                Double diplôme en 5ème année
                            </h4>
                            <p style={{  textDecoration: 'bold' }}>
                                5ème année remplacée par un double diplôme en Sécurité et Technologies Informatiques.
                            </p>
                            <h4 style={{ padding: '7px'}}>
                                Liste de références
                            </h4>
                            <div style={{ }}>
                                <a href='https://syllabus.insa-cvl.fr/fr/maquette/list?dept=3&status=1' target='_blank' rel='noreferrer'>
                                    Syllabus Gestion des Risques, INSA CVL
                                </a>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col style={{margin: 'auto',paddingBottom :'10px' }} md={6} lg={6} xl={6} sm={12}>
                    <Card style={{ borderRadius: '10px' }}>
                        <CardHeader style={{ border: '0px' }}>
                            <CardTitle>
                                <h2 style={{ paddingTop: '10px', textAlign: 'center' }}>
                                    Sécurité Informatique (STI)
                                    <p className='text-muted'>
                                        Diplôme d'ingénieur
                                    </p>
                                </h2>
                            </CardTitle>
                            <CardSubtitle>
                                <h3 style={{ textAlign: 'center', fontSize: '1.2rem' }} className='text-muted'>
                                    INSA Centre Val de Loire
                                </h3>
                            </CardSubtitle>
                        </CardHeader>
                        <CardBody style={{ padding: '10px' }}>
                            <p style={{ }}>
                                Le but du département Sécurité et Technologies Informatiques est d'apporter les bases théoriques, technologiques et techniques nécessaires à la conception, au développement et à la mise en œuvre de systèmes d'information sécurisés.
                            </p>
                            <h4 style={{ padding: '7px', }}>
                                Option de 4ème année : Sécurité Fondamentale
                            </h4>
                            <p style={{ }}>
                                Cette option se concentre sur la création des preuves de sécurité. Une ouverture à l'informatique quantique et l'architecture sécurité des systèmes à micronoyaux comme Windows y sont abordés.
                            </p>
                            <h4 style={{ padding: '7px', }}>
                                Option de 5ème année : Architecture, Administration, Audit et Analyse de Sécurité
                            </h4>
                            <p style={{ }}>
                                Les étudiants apprennent à identifier les menaces et les vulnérabilités, à mettre en place des mesures de protection et à réaliser des audits de sécurité et répondre aux incidents de sécurité.
                            </p>
                            <h4 style={{ padding: '7px', }}>
                                Liste de références
                            </h4>
                            <div style={{ }}>
                                <a href='https://cyber.gouv.fr/formations/ingenieur-diplome-de-linstitut-national-des-sciences-appliquees-centre-val-de-loire-1' target='_blank' rel='noreferrer'>
                                    Label SecNumedu, ANSSI
                                </a>
                                <br />
                                <a href='https://syllabus.insa-cvl.fr/fr/maquette/list?dept=1&status=1' target='_blank' rel='noreferrer'>
                                    Syllabus Sécurité Informatique, INSA CVL
                                </a>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );

};

export default Diplome;