import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import projectList from '../asset/data/projectList';
import { FaGithub } from 'react-icons/fa';


const IndividualProjectPages = () => {
    //read aftre the / in the url
    const url = window.location.pathname.split('/').pop();
    const urlProject = projectList.find((project) => project.link === `/projects/${url}`);
    if (urlProject) {
        return (
            <Container>
            <Row>
                <div className="project-page">
                <h1>{urlProject.title}</h1>
                {urlProject.image && (
                    <img src={urlProject.image} alt={urlProject.title} 
                    />
                )}

                <p>{urlProject.description}</p>
                <p>{urlProject.longDescription}</p>
                {urlProject.details && (
                    Object.entries(urlProject.details).map(([key, value], index) => (
                    <p key={index}>
                        <span style={{ fontWeight: 'bold' }}>{key}: </span>
                        {value}
                    </p>
                    ))
                )}

                <p><span style={{ fontWeight: 'bold' }}>Outils utilisés:  </span>{urlProject.tools.join(', ')}</p>

                {urlProject.github && (
                    urlProject.github.map((github, index) => (
                    <Button variant="dark"
                        href={github} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px', marginTop: '10px', marginBottom: '10px' }}>
                        <FaGithub style={{ marginRight: '10px' }} />
                        GitHub
                    </Button>
                    ))
                )}
                </div>
            </Row>
            <Row>
                <Col>
                <Button href="/projects" variant="secondary">&lt; Retour à la page des projets</Button>
                </Col>
            </Row>
            </Container>
        );
    }

    window.location.href = '/projects';
    return null;



};

export default IndividualProjectPages;