import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import headerBlob from '../asset/images/headerBlob.svg';

// "linear-gradient(215deg, #00d4ff 0%, #082b8f 10%, rgba(5,25,101,1) 35%, rgba(2,0,43,1) 50%, rgba(4,3,68,1) 65%, #082b8f 90%, #00d4ff 100%)"

function HomeHeader() {
    return (
        <>
        <div
            style={{
                minHeight: "100vh",
                display: "flex",
                background: "linear-gradient(215deg, #00d4ff 0%, #082b8f 5%, #031842 25%, #000 50%, #031842 75%, #082b8f 95%, #00d4ff 100%)",
                margin: "0",
                position: "fixed",
                width: "100vw",
                zIndex: "-1"
            }}
        >
            
        </div>
        <Container
            style={{
                minHeight: "100vh",
                display: "flex",
                paddingBottom: "150px",
                width: "100%",
                zIndex: "-1"
            }}

        >
        <Row className="my-auto" style={{ width: "100%" }}>
            <Col md={6} className="text-center"
                style={{
                    margin: "auto", position: "relative" 
                }}
            >
                <img src={headerBlob} style={{
                    width: "100%",
                    height: "auto"
                }}
                    alt=""
                />
                <Container
                    style={{
                        margin: "auto",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: "2"
                    }}
                >
                    <h1 className="display-1 text-center ">
                        <span
                            style={{
                                color: "var(--bs-light)",
                                fontWeight: "550"
                            }}
                        >Alcor Monllor</span>
                    </h1>
                    <p className="text-center "
                        style={{
                            color: "var( --bs-white)",
                            fontSize: "1.3rem",
                            fontWeight: "500"
                        }}
                        
                    >
                        Ingénieur en cybersécurité
                    </p>
           
                </Container>
            </Col>
            <Col md={6} className="item-center text-center"
                style={{
                    margin: "auto"
                }}
            >
                <p
                    style={{
                        color: "white",
                        fontSize: "1.5rem",
                    }}
                >
                    Bonjour, je suis ingénieur en cybersécurité avec un double diplôme en cybersécurité et gestion des risques.
                    Passionné par la résolution de problèmes exigeants, je cherche constamment à en apprendre plus.
                </p>
            </Col>
        </Row>
        <Row>
        </Row>
    </Container>
    </>
    );
}

export default HomeHeader;