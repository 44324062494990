import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from  './pages/About';
import Contact from  './pages/Contact';
import NavBar from  './components/NavBar';
import ProjectsPage from  './pages/ProjectsPage';
import Footer from 	'./components/Footer';
import Error404 from './pages/Error404';
import IndividualProjectPages from './pages/IndividualProjectPages';
import './App.css';


function App() {
	return (
		<div className="app-container">
			<Router >
				<NavBar />
				<div className="content-wrap">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/about" element={<About />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/projects" element={<ProjectsPage />} />
						<Route path="/projects/*" element={<IndividualProjectPages />} />
						{/* Add more routes as needed */}
						<Route path="*" element={<Error404 />} />

					</Routes>
				</div>
				<Footer />
			</Router>
		</div>
	);
}



export default App;