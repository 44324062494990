import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaLinkedin, FaGithub } from 'react-icons/fa';


const Footer = () => {
    return (
        <footer className="bg-dark text-white py-4" id="footer">
            <Container>
                <Row>
                    <Col md={6}>
                        <p>&copy; 2024 Monllor. Tous droits réservés.</p>
                    </Col>

                    <Col md={6} className="text-md-right">
                        <a href="https://www.linkedin.com/in/alcor-monllor/" className="text-white mx-2"><FaLinkedin /></a>
                        <a href="https://github.com/ManderVoronwe" className="text-white mx-2"><FaGithub /></a>
                    </Col>
                    
                </Row>
                <Row>
                    <Col md={12} className="text-center ">
                        <ul className="list-inline">
                            <li className="list-inline-item"><a href="/" className="text-white mx-2">Accueil</a></li>
                            <li className="list-inline-item"><a href="/about" className="text-white mx-2">À propos</a></li>
                            <li className="list-inline-item"><a href="/contact" className="text-white mx-2">Contact</a></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="text-center ">
                        <ul className="list-inline">
                            <li className="list-inline-item">Site web créé par Alcor</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;   

/*<Col md={4} className="text-md-right">
                        <div>
                            alcor[at]monllor[dot]fr
                        </div>
                    </Col>*/