import React from 'react';
import ProjectCard from './ProjectCard';
import { Container, Col} from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import projectList from '../asset/data/projectList';
import responsive from '../asset/data/responsive';





const Projects = () => {
  //read aftre the / in 


  return (
      <Container fluid style={{ padding: '10px', backgroundColor:'var(--bs-dark)' }}>
        <h2 style={{ padding: '10px', textAlign: 'center' 
        , color: 'var(--bs-light)', fontSize: '2rem'
        }}>Projects</h2>
        <Carousel responsive={responsive} autoPlay={true} infinite={true} autoPlaySpeed={3000}>
          {projectList.map((project, index) => (
            <Col key={index} style={{ padding: '10px' }}>
              <ProjectCard
                image={project.image} 
                title={project.title}
                description={project.description}
                link={project.link}
                tools={project.tools}
                github={project.github}
                
              />
            </Col>
          ))}
        </Carousel>
      </Container>
    );
};



export default Projects;